import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AuthenticationLayout from "./layouts/AuthenticationLayout";
import ErrorBoundary from "./layouts/ErrorBoundary";
import { localStorageSupported } from "./utils/localStorage";

const Home = lazy(() => import("./pages/Home"));
const Books = lazy(() => import("./pages/Books"));
const EditBook = lazy(() => import("./pages/EditBook"));
const Chapters = lazy(() => import("./pages/Chapters"));
const EditChapter = lazy(() => import("./pages/EditChapter"));
const EditTopic = lazy(() => import("./pages/EditTopic"));
const listAlbums = lazy(() => import("./pages/Albums"));
const listPhotos = lazy(() => import("./pages/Album"));
const ViewQuestions = lazy(() => import("./pages/ViewQuestions"));
const EditQuestions = lazy(() => import("./pages/EditQuestions"));
const ViewClassrooms = lazy(() => import("./pages/ViewClassrooms"));
const ViewHomeworks = lazy(() => import("./pages/ViewHomeworks"));
const ViewStudents = lazy(() => import("./pages/ViewStudents"));
const ViewHomeworkSubmissions = lazy(() =>
    import("./pages/ViewHomeworkSubmissions")
);
const ViewHomeworkQuestions = lazy(() =>
    import("./pages/ViewHomeworkQuestions")
);
const ViewSubmissionAnswers = lazy(() =>
    import("./pages/ViewSubmissionAnswers")
);
const CreateCampaigns = lazy(() => import("./pages/CreateCampaigns"));
const ViewCampaigns = lazy(() => import("./pages/ViewCampaigns"));
const Logs = lazy(() => import("./pages/Logs"));
const Schools = lazy(() => import("./pages/Schools"));
const CreateSchool = lazy(() => import("./pages/CreateSchool"));
const ViewSchool = lazy(() => import("./pages/ViewSchool"));
const EditSchool = lazy(() => import("./pages/EditSchool"));
const ViewProfiles = lazy(() => import("./pages/ViewProfiles"));
const Student = lazy(() => import("./pages/Student"));
const SamplePapers = lazy(() => import("./pages/SamplePapers"));
const ContentErrorLogs = lazy(() => import("./pages/ContentErrorLogs"));
const CollectionBook = lazy(() => import("./pages/CollectionBook"));
const CollectionBookDetail = lazy(() => import("./pages/CollectionBookDetail"));
const CreateCollectionBook = lazy(() => import("./pages/CreateCollectionBook"));
const UpdateCollectionBook = lazy(() => import("./pages/UpdateCollectionBook"));
const SortBooks = lazy(() => import("./pages/SortBooks"));
const SelectedQuestions = lazy(() => import("./pages/SelectedQuestions"));
const GetQuestions = lazy(() => import("./pages/GetQuestions"));
const LatexVoiceLogs = lazy(() => import("./pages/LatexVoiceLogs"));
const CreateNotification = lazy(() => import("./pages/CreateNotification"));
const UserAccess = lazy(() => import("./pages/UserAccess"));
const CbseSchools = lazy(() => import("./pages/CbseSchools"));
const ViewCbseSchool = lazy(() => import("./pages/ViewCbseSchool"));

const App = () => {
    if (!localStorageSupported()) {
        return (
            <div className="centered-item-at-screen">
                <div>
                    Oops!! Cant Open dashboard <br /> Cookies Error: cant access
                    localStorage
                </div>
            </div>
        );
    }

    return (
        <Suspense
            fallback={
                <div className="centered-item-at-screen">
                    <div className="loadingio-spinner-cube-4h8q9oewrtf">
                        <div className="ldio-9x2y98ulabc">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            }
        >
            <ErrorBoundary
                fallback={
                    <div className="centered-item-at-screen">
                        <div className="loadingio-spinner-cube-4h8q9oewrtf">
                            <div className="ldio-9x2y98ulabc">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="screen-state-label">
                            If its taking too long.
                            <br />
                            Try refreshing
                        </div>
                    </div>
                }
            >
                <Router>
                    <Route path="/" exact strict component={Home} />
                    <Route
                        path="/books"
                        exact
                        strict
                        children={<AuthenticationLayout Children={Books} />}
                    />
                    <Route
                        path="/books/edit/:bookId"
                        exact
                        strict
                        children={<AuthenticationLayout Children={EditBook} />}
                    />
                    <Route
                        path="/samplePapers/:bookId"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={SamplePapers} />
                        }
                    />
                    <Route
                        path="/chapters/:bookId"
                        exact
                        strict
                        children={<AuthenticationLayout Children={Chapters} />}
                    />
                    <Route
                        path="/chapters/edit/:bookId/:chapterId"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={EditChapter} />
                        }
                    />
                    <Route
                        path="/topics/edit/:bookId/:chapterId/:topicIndex"
                        exact
                        strict
                        children={<AuthenticationLayout Children={EditTopic} />}
                    />
                    <Route
                        path="/albums"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={listAlbums} />
                        }
                    />
                    <Route
                        path="/album/:albumKey"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={listPhotos} />
                        }
                    />
                    <Route
                        path="/viewQuestions"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewQuestions} />
                        }
                    />
                    <Route
                        path="/getQuestions"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={GetQuestions} />
                        }
                    />
                    <Route
                        path="/latexVoiceLogs"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={LatexVoiceLogs} />
                        }
                    />
                    <Route
                        path="/question/edit/:id"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={EditQuestions} />
                        }
                    />
                    <Route
                        path="/viewClassrooms"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewClassrooms} />
                        }
                    />
                    <Route
                        path="/student"
                        exact
                        strict
                        children={<AuthenticationLayout Children={Student} />}
                    />
                    <Route
                        path="/createCampaign"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={CreateCampaigns} />
                        }
                    />
                    <Route
                        path="/createNotification"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={CreateNotification}
                            />
                        }
                    />
                    <Route
                        path="/viewCampaigns"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewCampaigns} />
                        }
                    />
                    <Route
                        path="/viewHomeworks"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewHomeworks} />
                        }
                    />
                    <Route
                        path="/viewStudents"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewStudents} />
                        }
                    />
                    <Route
                        path="/viewHomeworkSubmissions"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={ViewHomeworkSubmissions}
                            />
                        }
                    />
                    <Route
                        path="/viewHomeworkQuestions"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={ViewHomeworkQuestions}
                            />
                        }
                    />
                    <Route
                        path="/viewSubmissionAnswers"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={ViewSubmissionAnswers}
                            />
                        }
                    />
                    <Route
                        path="/dashboardLogs"
                        exact
                        strict
                        children={<AuthenticationLayout Children={Logs} />}
                    />
                    <Route
                        path="/schools"
                        exact
                        strict
                        children={<AuthenticationLayout Children={Schools} />}
                    />
                    <Route
                        path="/schools/new"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={CreateSchool} />
                        }
                    />
                    <Route
                        path="/schools/view"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewSchool} />
                        }
                    />
                    <Route
                        path="/schools/edit/:schoolId"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={EditSchool} />
                        }
                    />
                    <Route
                        path="/viewProfiles"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewProfiles} />
                        }
                    />
                    <Route
                        path="/ContentErrorLogs"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ContentErrorLogs} />
                        }
                    />
                    <Route
                        path="/collection/books"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={CollectionBook} />
                        }
                    />
                    <Route
                        path="/collection/books/view"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={CollectionBookDetail}
                            />
                        }
                    />
                    <Route
                        path="/collection/books/new"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={CreateCollectionBook}
                            />
                        }
                    />
                    <Route
                        path="/collection/books/edit/:id"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={UpdateCollectionBook}
                            />
                        }
                    />
                    <Route
                        path="/sortBooks"
                        exact
                        strict
                        children={<AuthenticationLayout Children={SortBooks} />}
                    />
                    <Route
                        path="/selectedQuestions"
                        exact
                        strict
                        children={
                            <AuthenticationLayout
                                Children={SelectedQuestions}
                            />
                        }
                    />
                    <Route
                        path="/userAccess"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={UserAccess} />
                        }
                    />
                    <Route
                        path="/cbseSchools"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={CbseSchools} />
                        }
                    />
                    <Route
                        path="/cbseSchools/view"
                        exact
                        strict
                        children={
                            <AuthenticationLayout Children={ViewCbseSchool} />
                        }
                    />
                </Router>
            </ErrorBoundary>
        </Suspense>
    );
};

export default App;
