import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../src/styles/tailwind-output.css";
import "../src/styles/reactToastify-output.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// while deploying to prod or while building in local
// serviceWorker.register();

// while developing or running locally
serviceWorker.unregister();
