import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, evendId: null };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error });
    }
    render() {
        if (!this.state.error) {
            return this.props.children;
        } else {
            return this.props.fallback;
        }
    }
}

export default ErrorBoundary;
