// export const serverStringTs = "https://homeworkapp.ai/root2/ts"; // for prod
export const serverStringTs = "https://test-api.homeworkapp.ai/test/ts"; // for test
// export const serverStringTs = "http://localhost:8000"; // for local

// export const pdfServerStringTs = "https://prod-pdf-api.homeworkapp.ai"; // for prod
export const pdfServerStringTs = "https://test-pdf-api.homeworkapp.ai"; // for test
// export const pdfServerStringTs = "http://localhost:8000"; // for local

export const googleClientId =
    "236102906921-u2msktsujuv4gf4ufv9is5qg256ft97e.apps.googleusercontent.com";

// export const v1IdentityPoolId =
//     "ap-south-1:e2a4ec6f-6dad-47bf-883a-585f22f35083"; // for prod
export const v1IdentityPoolId =
    "ap-south-1:e2a4ec6f-6dad-47bf-883a-585f22f35083"; // for test

// export const v1BucketRegion = "ap-south-1"; // for prod
export const v1BucketRegion = "ap-south-1"; // for test

export const v1ContentBucket = "homeworkcontent"; // for prod
// export const v1ContentBucket = "testhomeworkcontent"; // for test

// export const v2IdentityPoolId =
//     "ap-south-1:93b66e21-4e43-4a08-9da9-098b86f979e7"; // for prod
export const v2IdentityPoolId =
    "ap-south-1:c3aba047-63c4-4e4b-af96-866431acb9fd"; // for test

// export const v2BucketRegion = "ap-south-1"; // for prod
export const v2BucketRegion = "ap-south-1"; // for test

// export const v2CdnPrefix = "https://cdn.homeworkapp.ai"; // for prod
export const v2CdnPrefix = "https://test-cdn.homeworkapp.ai"; // for test

// export const v2SchoolOnboardingBucket = "school-onboarding"; // for prod
export const v2SchoolOnboardingBucket = "test-school-onboarding"; // for test

// export const graphqlUri = "https://graphql.homeworkapp.ai"; // for prod
export const graphqlUri = "https://test-graphql.homeworkapp.ai"; // for test

// export const graphqlDatabase = "hw_app_prod"; // for prod
export const graphqlDatabase = "hw_app_test"; // for test
