import React, { useState, useEffect } from "react";
import { serverStringTs } from "../utils/config";
import axios from "axios";
import { Redirect } from "react-router-dom";

const AuthenticationLayout = ({ Children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticating, setAuthenticating] = useState(true);

    useEffect(() => {
        let tempProfile = window.localStorage.getItem("googleProfileObj");
        let tempToken = window.localStorage.getItem("googleTokenObj");
        let accessDetails = window.localStorage.getItem("accessDetails");
        if (!tempProfile || !tempToken) {
            setAuthenticated(false);
            setAuthenticating(false);
            return;
        }
        let parsedTempProfile = JSON.parse(tempProfile);
        if (parsedTempProfile && !parsedTempProfile.email) {
            setAuthenticated(false);
            setAuthenticating(false);
            return;
        }

        axios
            .get(
                `${serverStringTs}/prod/hw/getDashboardEmail?email=${parsedTempProfile.email}`
            )
            .then((response) => {
                var data = response.data;
                window.localStorage.setItem("accessDetails", JSON.stringify(data));
                if (data && data.count > 0) {
                    setTimeout(() => {
                        setAuthenticated(true);
                        setAuthenticating(false);
                    }, 600);
                    return;
                } else {
                    setAuthenticated(false);
                    setAuthenticating(false);
                    return;
                }
            })
            .catch(function (error) {
                console.log(error);
                setAuthenticated(false);
                setAuthenticating(false);
                return;
            });
    }, []);

    if (authenticating) {
        return (
            <div className="centered-item-at-screen">
                <div className="loadingio-spinner-cube-4h8q9oewrtf">
                    <div className="ldio-9x2y98ulabc">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    } else if (authenticated) {
        return <Children />;
    } else {
        return <Redirect to="/" />;
    }
};

export default AuthenticationLayout;
